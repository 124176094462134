'use client';

import { rgba } from 'polished';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';

export interface TableListProps {
  header?: ReactNode;
  items: ReactNode[];
}

export const TableList: FC<PropsWithChildren<TableListProps>> = (props) => {
  const { header, items } = props;
  return (
    <Root>
      {header && (
        <>
          <Row isHeader={true}>
            <Typography.Caption textAlign="center">{header}</Typography.Caption>
          </Row>

          {items.length !== 0 && <RowDivider />}
        </>
      )}

      {items.map((item, index) => (
        <Fragment key={index}>
          <Row>
            <Typography.Caption textAlign="center">{item}</Typography.Caption>
          </Row>

          {items.length - 1 !== index && <RowDivider />}
        </Fragment>
      ))}
    </Root>
  );
};

const Root = styled.div`
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Row = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isHeader' && prop !== 'isLast',
})<{ isHeader?: boolean; isLast?: boolean }>`
  padding: 8px;
  opacity: ${({ isHeader }) => (isHeader ? 1 : 0.5)};
`;

const RowDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => rgba(theme.foreground, 0.1)};
`;
