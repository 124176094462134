'use client';

import React from 'react';
import { ContextualPopup as ContextualPopupComponent } from './ContextualPopup';

import { ContextualPopupHeading } from './ContextualPopupHeading';
import { ContextualPopupSection } from './ContextualPopupSection';
import { ContextualPopupHelp } from './ContextualPopupHelp';
import { ContextualPopupGyroscopeVisualisation } from './ContextualPopupGyroscopeVisualisation';
import { ContextualPopupWorldMap } from './ContextualPopupWorldMap';

export class ContextualPopup extends React.Component<any, any> {
  public static Heading = ContextualPopupHeading;
  public static Section = ContextualPopupSection;

  public static Help = ContextualPopupHelp;

  public static GyroscopeVisualisation = ContextualPopupGyroscopeVisualisation;
  public static WorldMap = ContextualPopupWorldMap;

  render() {
    return <ContextualPopupComponent {...this.props} />;
  }
}
