'use client';

import { lighten, rgba } from 'polished';
import { FC, PropsWithChildren, ComponentPropsWithoutRef } from 'react';
import {
  CodeBlock as ReactCodeBlock,
  monokai,
} from 'react-code-blocks';
import styled from 'styled-components';

export interface CodeBlockProps
  extends ComponentPropsWithoutRef<typeof ReactCodeBlock> {
  className?: string;
}

export const CodeBlock: FC<PropsWithChildren<CodeBlockProps>> = props => (
  <Root>
    <ReactCodeBlock
      theme={{
        ...monokai,
        commentColor: rgba('white', 0.3),
        backgroundColor: 'none',
      }}
      {...props}
    />
  </Root>
);

const Root = styled.div`
  border-radius: 8px;
  font-family: Inconsolata, monospace;
  font-weight: bold;
  font-size: 0.9375rem;
  overflow: hidden;
  padding: 8px;
  background: ${({ theme }) =>
    theme.dark ? lighten(0.1)(theme.background) : theme.foreground};
`;
