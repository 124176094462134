'use client';

import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { usePrefersColorScheme } from '../../hooks/usePrefersColorScheme';
import { useWebGLRenderer } from '../../../hooks/useWebGLRenderer';
import { Flex } from '../../layout';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { Reveal } from '../Reveal';

export const ContextualPopupGyroscopeVisualisation: FC<
  PropsWithChildren<{
    width?: number;
    height?: number;
  }>
> = ({ width = 320, height = 240 }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { renderer } = useWebGLRenderer();
  const [hasData, setHasData] = useState(false);
  const { colorScheme } = usePrefersColorScheme();

  const alphaRef = useRef<HTMLElement>(null);
  const betaRef = useRef<HTMLElement>(null);
  const gammaRef = useRef<HTMLElement>(null);

  const gridHelper = useRef(new THREE.GridHelper(20, 100));
  const axesHelper = useRef(new THREE.AxesHelper(0.5));

  const [permission, setPermission] = useState<
    'unknown' | 'granted' | 'denied'
  >('unknown');

  let camera: THREE.PerspectiveCamera;
  let scene = useRef(new THREE.Scene()).current;

  useEffect(() => {
    gridHelper.current = new THREE.GridHelper(
      20,
      100,
      colorScheme === 'dark' ? 0x666666 : 0xcccccc,
      colorScheme === 'dark' ? 0x666666 : 0xcccccc
    );
  }, [colorScheme]);

  useEffect(() => {
    if (!renderer) return;

    if (wrapperRef.current?.firstChild) {
      wrapperRef.current.removeChild(wrapperRef.current.firstChild);
    }
    wrapperRef.current?.appendChild(renderer.domElement);

    var mainGroup = new THREE.Group();
    scene.add(mainGroup);

    camera = new THREE.PerspectiveCamera(75, width / height, 0.01, 20);

    camera.position.y = 1;

    const material = new THREE.MeshNormalMaterial();
    const geometry = new THREE.BoxGeometry(0.25, 0.25, 0.25);
    const mesh = new THREE.Mesh(geometry, material);

    axesHelper.current.translateY(0.005);

    mainGroup.add(mesh);
    mainGroup.add(gridHelper.current);
    mainGroup.add(axesHelper.current);

    scene.add(mainGroup);

    renderer.setSize(width, height);

    const animate = () => {
      requestAnimationFrame(animate);

      renderer.render(scene, camera);
    };

    animate();

    const resizeHandler = () => {
      camera.updateProjectionMatrix();
      camera.aspect = width / height;
      camera.lookAt(new THREE.Vector3(0, 0, 0));

      renderer.setSize(width, height);
      renderer.setPixelRatio(devicePixelRatio);
      renderer.render(scene, camera);
    };
    resizeHandler();

    const deviceOrientationHandler = ({
      alpha,
      gamma,
      beta,
    }: DeviceOrientationEvent) => {
      if (alpha && beta && gamma) {
        if (!hasData) setHasData(true);

        if (alphaRef.current)
          alphaRef.current.textContent = `${Math.round(alpha)}`;
        if (betaRef.current)
          betaRef.current.textContent = `${Math.round(beta)}`;
        if (gammaRef.current)
          gammaRef.current.textContent = `${Math.round(gamma)}`;

        mainGroup.rotation.y = THREE.MathUtils.degToRad(alpha);

        mainGroup.rotation.x = THREE.MathUtils.degToRad(beta + 180);
        mainGroup.rotation.z = THREE.MathUtils.degToRad(gamma * 2); // + 180);
      }
    };

    addEventListener('resize', resizeHandler);
    addEventListener('deviceorientation', deviceOrientationHandler, true);

    return () => {
      removeEventListener('resize', resizeHandler);
      removeEventListener('deviceorientation', deviceOrientationHandler, true);
    };
  }, [renderer]);

  const hasDeviceMotionEvent =
    typeof DeviceMotionEvent !== 'undefined' &&
    typeof (DeviceMotionEvent as any).requestPermission === 'function';

  const onAllowDeviceOrientationClick = () => {
    (DeviceMotionEvent as any)
      .requestPermission()
      .then((response: any) => {
        if (response == 'granted') {
          setPermission('granted');
        } else {
          setPermission('denied');
        }
      })
      .catch(console.error);
  };

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
      }}
    >
      {hasData && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            padding: 8,
            boxSizing: 'border-box',
          }}
        >
          <Flex flexDirection="column" justifyContent="space-around">
            <Reveal inactiveOpacity={0.25} activeOpacity={0.5}>
              <Typography.Caption>
                Alpha: <span ref={alphaRef} />
              </Typography.Caption>
              <Typography.Caption>
                Beta: <span ref={betaRef} />
              </Typography.Caption>
              <Typography.Caption>
                Gamma: <span ref={gammaRef} />
              </Typography.Caption>
            </Reveal>
          </Flex>
        </div>
      )}

      {!hasData && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          // @ts-ignore
          style={{ height }}
        >
          <Box marginBottom={8}>
            <Typography.Caption size="L" textAlign="center">
              No gyroscope data available
            </Typography.Caption>
          </Box>

          <Box marginBottom={16}>
            <Reveal inactiveOpacity={0.25} activeOpacity={0.5}>
              <Typography.Caption textAlign="center">
                {hasDeviceMotionEvent ? (
                  permission === 'unknown' ? (
                    <>
                      Your device requires you to allow this website to read the
                      data from your device orientation senor.
                    </>
                  ) : permission === 'denied' ? (
                    <>
                      The permission to read the data from your device
                      orientation sensor has been denied.
                    </>
                  ) : (
                    <>No data has been received from the sensor yet.</>
                  )
                ) : (
                  <>Your device does not seem to support device orientation.</>
                )}
              </Typography.Caption>
            </Reveal>
          </Box>

          {hasDeviceMotionEvent && permission === 'unknown' && (
            <Button size="S" onClick={onAllowDeviceOrientationClick}>
              Allow Device Orientation
            </Button>
          )}
        </Flex>
      )}

      <div
        ref={wrapperRef}
        style={{
          position: 'absolute',
          zIndex: -1,
          top: 0,
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          opacity: hasData ? 1 : 0.1,
        }}
      />
    </div>
  );
};
