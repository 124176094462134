'use client';

import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../themes';
import {
  getResponsiveProperty,
  ResponsiveProperty,
} from '../../../utils/responsive';
import { SpacingType } from '../../../utils/spacing';

export type FlexGap = SpacingType;

export interface FlexProps {
  children?: React.ReactNode;
  display?: ResponsiveProperty<'flex' | 'inline-flex' | 'none'>;
  flexDirection?: ResponsiveProperty<React.CSSProperties['flexDirection']>;
  flexWrap?: ResponsiveProperty<React.CSSProperties['flexWrap']>;
  flexFlow?: ResponsiveProperty<React.CSSProperties['flexFlow']>;
  justifyContent?: ResponsiveProperty<React.CSSProperties['justifyContent']>;
  alignItems?: ResponsiveProperty<React.CSSProperties['alignItems']>;
  alignContent?: ResponsiveProperty<React.CSSProperties['alignContent']>;
  gap?: ResponsiveProperty<FlexGap>;
  height?: ResponsiveProperty<React.CSSProperties['height']>;
  minHeight?: ResponsiveProperty<React.CSSProperties['minHeight']>;
  style?: React.CSSProperties;
}

const FlexComponent: React.FC<React.PropsWithChildren<FlexProps>> = ({
  display,
  flexDirection,
  flexWrap,
  flexFlow,
  justifyContent,
  alignItems,
  alignContent,
  gap,
  height,
  minHeight,
  ...props
}) => <div {...props} />;

export const Flex = styled(FlexComponent)`
  ${({ theme, display = 'flex' }) =>
    getResponsiveProperty('display', display, theme as Theme)}
  ${({ theme, flexDirection }) =>
    getResponsiveProperty('flexDirection', flexDirection, theme as Theme)}
  ${({ theme, flexWrap }) =>
    getResponsiveProperty('flexWrap', flexWrap, theme as Theme)}
  ${({ theme, flexFlow }) =>
    getResponsiveProperty('flexFlow', flexFlow, theme as Theme)}
  ${({ theme, justifyContent }) =>
    getResponsiveProperty('justifyContent', justifyContent, theme as Theme)}
  ${({ theme, alignItems }) =>
    getResponsiveProperty('alignItems', alignItems, theme as Theme)}
  ${({ theme, alignContent }) =>
    getResponsiveProperty('alignContent', alignContent, theme as Theme)}
  ${({ theme, gap }) => getResponsiveProperty('gap', gap, theme as Theme)}
  ${({ theme, height }) =>
    getResponsiveProperty('height', height, theme as Theme)}
  ${({ theme, minHeight }) =>
    getResponsiveProperty('minHeight', minHeight, theme as Theme)}
`;
