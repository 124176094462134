'use client';

import React from 'react';
import styled from 'styled-components';

export interface FlexItemProps {
  alignSelf?: React.CSSProperties['alignSelf'];
  flex?: React.CSSProperties['flex'];
}

const FlexItemComponent: React.FC<React.PropsWithChildren<FlexItemProps>> = ({
  flex,
  alignSelf,
  ...props
}) => <div {...props} />;

export const FlexItem = styled(FlexItemComponent)`
  align-self: ${({ alignSelf }) => alignSelf};
  flex: ${({ flex }) => flex};
`;
